import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Particle from "../Particle";
import ResumeContent from "./ResumeContent";
import axios from "axios";
import pdf from "../../Assets/Vikas-Surera.pdf";
import { AiOutlineDownload } from "react-icons/ai";

function Resume() {
  const uri = "https://porfolio-backend.vercel.app/ranks/getRanks";
  const [spojRank, upadteSpojRank] = useState(0);
  const [hackerrank, upadteHackerank] = useState(0);
  const [sem, upadateSem] = useState(0);
  const [cgpa, upadteCgpa] = useState(0);

  useEffect(() => {
    axios
      .get(uri)
      .then((res) => {
        upadteSpojRank(res.data.message[0].spojRank);
        upadteHackerank(res.data.message[1].hackerrank);
        upadteCgpa(res.data.message[2].cgpa);
        upadateSem(res.data.message[3].sem);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Container fluid className="resume-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", position: "relative" }}>
          <Button variant="primary" href={pdf} target="_blank">
            <AiOutlineDownload />
            &nbsp;Download CV
          </Button>
        </Row>
        <Row className="resume">
          <Col md={6} className="resume-left">
            <h3 className="resume-title">Experience</h3>
                        <ResumeContent
                            title="OneTap,  Remote - Full-stack (MERN) and DevOps Engineer"
                            date="April 2022 - Present" 
                            content={[
                                "Created CI/CD pipeline using Github Actions, Docker with added automatic slack notifications for failed and successful tests & deployment",
                                "Frontend automated UI Testing - Jest + Puppeteer (Build, Test, Deploy)",
                                "Frontend & Backend Development (React, Express, Node)",
                            ]}
                        />
                        <ResumeContent
                            title="BitPack,  Remote - Full-stack Developer"
                            date="February 2022 - June 2022" 
                            content={[
                                "Flutter app development with razorpay payment gateway integration, REST API, Git and Github use.",
                                "React (Next & Typescript)  frontend development with REST API and UI designs implementation.",
                                "UI implementation based on designs provided in Adobe XD files.",
                            ]}
                        />
                        <ResumeContent
                            title="OneTap,  Remote - Full-stack Developer (MERN)"
                            date="April 2021 - January 2022" 
                            content={[
                                "Responsible for development of new features into the product",
                                "Improved UI/UX flow & implemented automatic QR codes for each use rprofile for easy check-in of users",
                                "Backend development, infrastructure management (devOps), Frontend development & Testing (Jest Js)",
                            ]}
                        />
                        <ResumeContent
                            title="AssetAcquire,  Remote - Frontend React Developer"
                            date="August 2021 - September 2021" 
                            content={[
                                "Developed website for company from scratch using React & Redux with UI implementation based on Adobe XD designs.",
                                "Involvement: Git, Github, REST API, Google Map API setup and integration",
                            ]}
                        />
                        <ResumeContent
                            title="Assurance Technologies, Remote - Web Developer"
                            date="April 2021 - June 2021"
                            content={[
                                "Designed the whole website UI, developed and launched it on Express serve ron AWS ec2 instance",
                                "Created automatic email delivery on form submission on website using Simple Email Service (AWS SES).",
                                "Added a feature where administrators get all forms and data submitted straight into their email inbox",
                            ]}
                        />
                        <ResumeContent
                            title="Vishisht Business Solution Pvt Ltd, Remote - App Developer"
                            date="December 2020 - June 2021"
                            content={[
                                "Initiated the work on flutter apps with tech specs RES API, Git and Github (version control)",
                                "Successfully created Teacher and Student Apps under Escola Learning Management System.",
                                "Implemented caching of image, files & data, UI and state management (provider package).",
                            ]}
                        />
          </Col>
          <Col md={6} className="resume-right">
          <h3 className="resume-title">Entrepreneurial Experience</h3>
                        <ResumeContent
                            title="Bloggerboy, Remote - Excecutive"
                            date="April 2020 - July 2020"
                            content={[
                                "Started a blogging website in class 10th for posting informative blogs.",
                                "Further lead a small team of authors, editors and marketer for writing, publishing and social media marketing of the blogs.",
                            ]}
                        />

                        <h3 className="resume-title">Education</h3>
                        <ResumeContent
                            title="B.Tech Mechanical Engineering [IIT Kharagpur]"
                            date="2019 - 2023"
                            //content={[`CGPA: ${cgpa} (Till ${sem}th Sem)`]}
                            content={["CGPA: 8.68"]}
                        />
                        <ResumeContent
                            title="12TH BOARD [Kendriya Vidyalaya No 1, Gandhinagar"
                            date="2018 - 2019"
                            content={["Precentage: 94.2%"]}
                        />
                        <ResumeContent
                            title="10TH BOARD [Kendriya Vidyalaya No 1, Gandhinagar"
                            date="2017 - 2018"
                            content={["CGPA: 10"]}
                        />

                        <h3 className="resume-title">Academic Awards</h3>
                        <ResumeContent
                            title=""
                            content={[
                                "International Rank 112 & Zonal Rank 15 in National Cyber Olympiad 2019",
                                "Rank 79 of the top 100 National Achievers of class 12-PCM in NTSE 2019",
                                "International Rank 102 & Zonal Rank 7 in National Cyber Olympiad 2016",
                            ]}
                        />

                        <h3 className="resume-title">Extracurricular Activities</h3>
                        <ResumeContent
                            title="Sports"
                            content={[
                                "Won Silver medal in 4x400m relay at Interhall tournament IIT Kharagpur 2022",
                                "Bronze Medal in Long Jump at Athletics Regional Meet (Gujarat Region), 2016",
                                "Silver Medal in Football at Sports Regional Meet (Rajasthan Region) 2012 with recognition as one of the top 6 players of tournament.",
                            ]}
                        />
          </Col>
        </Row>
        <Row style={{ justifyContent: "center", position: "relative" }}>
          <Button variant="primary" href={pdf} target="_blank">
          <AiOutlineDownload />&nbsp;Download CV
          </Button>
        </Row>
      </Container>
    </Container>
  );
}

export default Resume;
