import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";

// import leaf from "../../Assets/Projects/leaf.png";
// import emotion from "../../Assets/Projects/emotion.jpeg";
// import editor from "../../Assets/Projects/codeEditor.png";
// import chatify from "../../Assets/Projects/chatify.png";
// import suicide from "../../Assets/Projects/suicide.png";
// import bitsOfCode from "../../Assets/Projects/blog.png";
import nssc from '../../Assets/Projects/nssc.PNG';
import quiz from '../../Assets/Projects/quiz.PNG';
import learning from '../../Assets/Projects/learning.PNG';
import plier from '../../Assets/Projects/plier.PNG';

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>

          

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={nssc}
              isBlog={false}
              title="National Students Space Challenge 2020"
              description=" Designed and developed user-friendly website National Students' Space Challenge 20, one of the largest space tech fest of India"
              link="https://test.nssc.in"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={learning}
              isBlog={false}
              title="Learning Management System"
              description="Initiated the work on flutter (by Google) apps (Teacher App and Student App) with tech specs REST API, Git, State Management and Caching"
              //link="https://test.nssc.in"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={quiz}
              isBlog={false}
              title="Space Quiz Platform"
              description="Designed the UI and developed quiz platform for Space Quiz event with participants over 350+ using PHP, MySQL and frontend tech (HTML, CSS)"
              link="https://youtu.be/n9E7Aq30P28"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={plier}
              isBlog={false}
              title="Parallel Jaw Plier Synthesis & Simulation"
              description="Lead a team to work on synthesis and simulation of parallel jaw plier mechanism with graphical and numberical analysis in MATLAB. Designed the 3D model and simulation of plier mechanism in SolidWorks."
              link="https://www.youtube.com/watch?v=yb3j_eGvBms"
            />
          </Col>

        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
