import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Vikas Surera </span>
            from <span className="purple"> Alwar, Rajasthan, India.</span>
            <br />I am a developer persuing my B. Tech in Mechanical Engineering from IIT Kharagpur
            <br />
            <br />
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Playing Football
            </li>
            <li className="about-activity">
              <ImPointRight /> Writting Blogs
            </li>
            <li className="about-activity">
              <ImPointRight /> Exploring Creative Things
            </li>
          </ul>

          <p style={{ marginBlockEnd: 0, color: "rgb(155 126 172)" }}>
            "Rise till you reach your destination!"{" "}
          </p>
          <footer className="blockquote-footer">Vikas</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
